import React from "react";
import { graphql, PageProps, withPrefix } from "gatsby";
import Layout from "../../components/Layout";
import { LocalisedPageContext } from "../../types/PageTypes";
import { ImageInterface, LocalizedContextInterface, SanityColorList, Seo } from "../../types/SanityTypes";
import StructuredData from "../../components/StructuredData";
import { Helmet } from "react-helmet";
import SEO from "../../components/Seo";
import HeroBanner from "../../components/HeroBanner";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import ShoppingBag from "../../components/ShoppingBag";

type BagPageQueryProps = {
  bagPage: {
    _id: string;
    name: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      heroImage: ImageInterface;
      isVideoBanner?: boolean;
    };
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type BagPageContext = LocalisedPageContext;

export const data = graphql`
  query ($_id: String, $language: String) {
    bagPage: sanityBag(_id: { eq: $_id }) {
      ...SanityBagFieldsFull
    }
    ...LocalizedContext
  }
`;

const Bag = (props: PageProps<BagPageQueryProps, BagPageContext>) => {
  const bagPage = props.data.bagPage;
  const language = props.pageContext.language;
  const { htmlLang, siteUrl } = useSiteMetadata(language);
  const localizedPath = withPrefix(getLocalizedPath(bagPage.slug?.current, bagPage._id));
  const sanityImage = bagPage.banner?.heroImage;
  const heroImage = bagPage.banner?._rawHeroImage;
  const heroVideo = bagPage.banner?._rawHeroVideo;
  const heroTitle = bagPage.banner?.heading;
  const isVideoBanner = bagPage.banner?.isVideoBanner;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName="search"
      pageType={"products"}
      analyticsTagMeta={{ promoId: "10" }}
    >
      <StructuredData
        type={"bagPage"}
        data={bagPage.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${bagPage.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={bagPage.seo.metaTitle}
        description={bagPage.seo.metaDescription}
        imageUrl={bagPage.banner._rawHeroImage.asset.url}
        imageAlt={bagPage.banner._rawHeroImage.alt}
      />
      {(heroImage || heroVideo) && (
        <HeroBanner
          title={heroTitle}
          isVideoBanner={isVideoBanner}
          video={heroVideo}
          sanityImage={sanityImage}
          alignment={bagPage.banner.contentAlignment}
          isSmallBanner={true}
        />
      )}
      <ShoppingBag />
    </Layout>
  );
};

export default Bag;
