import React, { FunctionComponent, MouseEvent, useEffect } from "react";
import { Link } from "../Link";
import Button from "../Button";

import { navigate } from "gatsby";
import axios from "axios";
import { getLocalStorageItem } from "../../services/salesforce/helper";
import EcommerceContext from "../../services/EcommerceContextService";
import { scCheckoutView } from "../../analytics/scCheckout";

import "./styles.scss";

interface BagTotalPriceInterface {
  shippingCost: number;
  shipmentPromotionPrice: number;
  salesTaxCost: number;
  orderDiscountPrice?: number;
  totalCost: number;
  shippmentMethodId: string;
  setBagValidationError: (val: boolean) => void;
  bagValidationError: boolean;
  hasSubscriptions: boolean;
}

const BagTotalPrice: FunctionComponent<BagTotalPriceInterface> = props => {
  const {
    shippingCost,
    salesTaxCost,
    orderDiscountPrice,
    totalCost,
    shipmentPromotionPrice,
    shippmentMethodId,
    setBagValidationError,
    bagValidationError,
    hasSubscriptions
  } = props;
  const { isUserAuthenticated } = React.useContext(EcommerceContext);

  useEffect(() => {
    axios(`/.netlify/functions/salesforce-set-user-bag-cookies`, {
      method: "POST",
      data: { Authorization: `Bearer ${getLocalStorageItem("access_token")}` }
    }).catch(error => console.log(error));
  }, []);

  const handleValidation = async (e: MouseEvent<HTMLButtonElement>) => {
    if (!shippmentMethodId) {
      e.preventDefault();
      setBagValidationError(true);
    } else {
      setBagValidationError(false);
      scCheckoutView();
      navigate("/checkout");
    }
  };

  return (
    <div className="bag-price__wrapper">
      <div className="bag-price__shippingCost">
        <p className="bag-price__shippingCost-label">Shipping Cost</p>
        <p className="bag-price__shippingCost-price">${shippingCost}</p>
      </div>

      {shipmentPromotionPrice ? (
        <div className="bag-price__shippingPromotion">
          <p className="bag-price__shippingPromotion-label">Shipping Discount</p>
          <p className="bag-price__shippingPromotion-price">-${shipmentPromotionPrice}</p>
        </div>
      ) : null}

      {orderDiscountPrice ? (
        <div className="bag-price__orderDiscount">
          <p className="bag-price__orderDiscount-label">Order Discount</p>
          <p className="bag-price__orderDiscount-price">-${orderDiscountPrice}</p>
        </div>
      ) : null}

      <div className="bag-price__totalCost">
        <p className="bag-price__totalCost-label">
          <strong>Estimated Total (Taxes & Shipping Costs Determined at Checkout)</strong>
        </p>
        <p className="bag-price__totalCost-price">${totalCost}</p>
      </div>
      <div className="bag-price__checkout">
        {!isUserAuthenticated ? (
          <Link to="/login?checkout=true" className="bag-price__checkout-link" asButton="nonary" buttonForm="br-50">
            Login and Checkout
          </Link>
        ) : null}
        {isUserAuthenticated || !hasSubscriptions ? (
          <Button
            className="bag-price__checkout-link"
            variant="nonary"
            form="br-50"
            onClick={handleValidation}
            disabled={bagValidationError}
          >
            {!isUserAuthenticated ? "Guest" : null} Checkout
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BagTotalPrice;
