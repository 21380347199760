import React, { FC, useState } from "react";

import "./styles.scss";

interface BagOverPriceInterface {
  basketThresholdValue: number;
}

const BagOverPrice: FC<BagOverPriceInterface> = props => {
  const { basketThresholdValue } = props;
  const [hideOverPriceMessage, setHideOverPriceMessage] = useState(false);
  return (
    <>
      {!hideOverPriceMessage ? (
        <div className="shopping-bag__overPrice">
          <p className="shopping-bag__overPrice-message">Maximum order value allowed is {basketThresholdValue}</p>
          <button className="shopping-bag__overPrice-close" onClick={_ => setHideOverPriceMessage(true)}>
            x
          </button>
        </div>
      ) : null}
    </>
  );
};

export default BagOverPrice;
