import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { getProductQuanity, processItemResponse } from "../../services/salesforce/helper";
import BagPromoCode from "../BagPromoCode";
import BagShippment from "../BagShippment";
import BagTotalPrice from "../BagTotalPrice";
import CartItems, { CartItemsInterface } from "../CartItems";
import { Link } from "../Link";
import ProductCategoryLoader from "../ProductCategoryLoader";
import EcommerceContext from "../../services/EcommerceContextService";
import { LocalizedContext } from "../../services/LocalizedContextService";
import LoaderIcon from "../../images/icons/loader";
import BagOverPrice from "../BagOverPrice";
import { scCartView } from "../../analytics/scCartView";

import "./styles.scss";

const ShoppingBag: FunctionComponent = () => {
  const ecommerceContext = useContext(EcommerceContext);
  const {
    sanitySiteSettings: { basketThresholdValue }
  } = useContext(LocalizedContext);
  const [bagEmpty, setBagEmpty] = useState(false);
  const [productItems, setProductItems] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [couponItemId, setCouponItemId] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [shipmentId, setShipmentId] = useState("");
  const [shipmentMethodId, setShipmentMethodId] = useState("");
  const [shipmentPromotionName, setShipmentPromtionName] = useState("");
  const [shipmentPromotionPrice, setShipmentPromotionPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [salesTaxCost, setSalesTaxCost] = useState(0);
  const [orderDiscountPrice, setOrderDiscountPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loader, setLoader] = useState(false);
  const [bagValidationError, setBagValidationError] = useState(false);
  const { bagItems, isLoading } = ecommerceContext;
  const [hasSubscriptions, setHasSubscriptions] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setBagEmpty(false);
    } else {
      setBagEmpty(!productItems.length);
    }
  }, [productItems, isLoading]);

  useEffect(() => {
    if (Object.entries(bagItems).length == 0) {
      return;
    }
    scCartView(bagItems.productItems);
    const couponItems = bagItems.couponItems;
    const shipments = bagItems.shipments;
    const orderPriceAdjustments = bagItems.orderPriceAdjustments;
    const shippingItems = bagItems.shippingItems;
    setProductItems(processItemResponse(bagItems));
    setProductCount(getProductQuanity(bagItems.productItems));
    setTotalPrice(bagItems.orderTotal);
    if (couponItems && couponItems.length && couponItems[0].valid) {
      const couponItem = couponItems[0];
      setCouponItemId(couponItem.couponItemId);
      setCouponCode(couponItem.code);
    } else {
      setCouponItemId("");
      setCouponCode("");
    }
    if (shipments && shipments.length) {
      const shipment = shipments[0];
      setShipmentId(shipment.shipmentId);
      if (shipment.shippingMethod) {
        setShipmentMethodId(shipment.shippingMethod.id);
        setShippingCost(shipment.shippingMethod.price);
      }
    }
    if (orderPriceAdjustments && orderPriceAdjustments.length) {
      const orderAdjustmentPrice = orderPriceAdjustments[0];
      setOrderDiscountPrice(Math.abs(Number(orderAdjustmentPrice?.price)));
    } else {
      setOrderDiscountPrice(0);
    }
    if (shippingItems && shippingItems.length) {
      const shippingItem = shippingItems[0];
      if (shippingItem.priceAdjustments && shippingItem.priceAdjustments.length) {
        const priceAdjustment = shippingItem.priceAdjustments[0];
        const { price, itemText } = priceAdjustment;
        setShipmentPromtionName(itemText);
        setShipmentPromotionPrice(Math.abs(price));
      } else {
        setShipmentPromotionPrice(0);
        setShipmentPromtionName("");
      }
    }
  }, [bagItems]);

  useEffect(() => {
    if (shipmentId) {
      setBagValidationError(false);
    }
  }, [shipmentMethodId]);

  useEffect(() => {
    setBagValidationError(totalPrice > basketThresholdValue);
  }, [basketThresholdValue, totalPrice]);

  useEffect(() => {
    productItems && productItems.length
      ? setHasSubscriptions(productItems.filter((productItem: any) => productItem?.c_hasSmartOrderRefill).length > 0)
      : setHasSubscriptions(false);
  }, [productItems]);

  return (
    <div className="shopping-bag shopping-bag__wrapper">
      {!ecommerceContext.isUserAuthenticated && hasSubscriptions ? (
        <p className="shopping-bag__login-warning">
          <span>Your shopping bag contains one or more SheaScription refill products. Please </span>
          <span>
            <a className=" " href="/login/">
              login
            </a>
          </span>
          <span> or </span>
          <span>
            <a className=" " href="/signup/">
              create a new account
            </a>
          </span>
          <span> to continue checkout process.</span>
        </p>
      ) : null}
      {productItems && productItems.length ? (
        <div className="shopping-bag__list">
          <div className="shopping-bag__header row">
            <Link className="shopping-bag__link grid-col-4" to="/">
              Continue Shopping
            </Link>
            <p className="shopping-bag__productCount grid-col-3 text-center">
              <h5>{productCount} Items</h5>
            </p>
            <div className="shopping-bag__message text-right grid-col-5">
              <span>Need Help? Call</span>
              <span>
                <Link tel={true} to="tel:1-631-842-8800" className="shopping-bag__link ">
                  1-631-842-8800
                </Link>
              </span>
            </div>
            <hr />
          </div>
          <div className="shopping-bag__list-items row">
            <div className="col-md-7">
              {totalPrice > basketThresholdValue ? <BagOverPrice basketThresholdValue={basketThresholdValue} /> : null}
              {productItems.map((productItem: CartItemsInterface, index: number) => (
                <CartItems
                  key={index + 1}
                  id={productItem.id}
                  images={productItem.images}
                  name={productItem.name}
                  quantity={productItem.quantity}
                  productItemId={productItem.productItemId}
                  basePrice={productItem.basePrice}
                  priceAfterItemDiscount={productItem.priceAfterItemDiscount}
                  priceAfterOrderDiscount={productItem.priceAfterOrderDiscount}
                  price={productItem.price}
                  c_SorMonthInterval={productItem.c_SorMonthInterval}
                  c_SorPeriodicity={productItem.c_SorPeriodicity}
                  c_SorWeekInterval={productItem.c_SorWeekInterval}
                  c_hasSmartOrderRefill={productItem.c_hasSmartOrderRefill}
                />
              ))}
            </div>
            <div className="col-md-5 position-relative">
              <BagPromoCode couponId={couponItemId} couponCode={couponCode} setLoader={setLoader} />
              <BagShippment
                shippmentId={shipmentId}
                shippmentMethodId={shipmentMethodId}
                shipmentPromotionName={shipmentPromotionName}
                shipmentPromotionPrice={shipmentPromotionPrice}
                setLoader={setLoader}
                bagValidationError={bagValidationError}
              />
              <BagTotalPrice
                shippingCost={shippingCost}
                orderDiscountPrice={orderDiscountPrice}
                totalCost={totalPrice}
                salesTaxCost={salesTaxCost}
                shipmentPromotionPrice={shipmentPromotionPrice}
                shippmentMethodId={shipmentMethodId}
                setBagValidationError={setBagValidationError}
                bagValidationError={bagValidationError}
                hasSubscriptions={hasSubscriptions}
              />
              {loader && <ProductCategoryLoader />}
            </div>
          </div>
        </div>
      ) : null}

      {bagEmpty && (
        <div className="shopping-bag__empty">
          <h2 className="shopping-bag__empty-title"> your bag is empty </h2>
          <Link to="/" className="shopping-bag__link">
            continue shopping
          </Link>
          <div className="shopping-bag__message">
            <span>Need Help? Call</span>
            <span>
              <Link tel={true} to="tel:1-631-842-8800" className="shopping-bag__link">
                1-631-842-8800
              </Link>
            </span>
          </div>
        </div>
      )}
      {isLoading && !(productItems && productItems.length) && (
        <LoaderIcon width={100} height={100} className="loaderIcon" />
      )}
    </div>
  );
};

export default ShoppingBag;
