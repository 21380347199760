import React, { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from "react";
import { getShipperDetails, updateShipmentDetails } from "../../services/salesforce/Bag";

import "./styles.scss";
import EcommerceContext from "../../services/EcommerceContextService";

interface BagShippmentInterface {
  shippmentId: string;
  shippmentMethodId: string;
  shipmentPromotionName: string;
  shipmentPromotionPrice: number;
  setLoader: (loader: boolean) => void;
  bagValidationError: boolean;
}

const BagShippment: FunctionComponent<BagShippmentInterface> = props => {
  const {
    shippmentId,
    shippmentMethodId,
    shipmentPromotionName,
    shipmentPromotionPrice,
    setLoader,
    bagValidationError
  } = props;
  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectShipmentMethodId, setSelectShipmentMethodId] = useState("");
  const { fetchItems } = useContext(EcommerceContext);

  useEffect(() => {
    if (shippmentMethodId) {
      setSelectShipmentMethodId(shippmentMethodId);
    }
  }, [shippmentMethodId]);

  useEffect(() => {
    if (shippmentId) {
      fetchShipperDetails();
    }
  }, [shippmentId]);

  const fetchShipperDetails = async () => {
    try {
      const response = await getShipperDetails(shippmentId);
      const applicableShippingMethods = response?.data?.applicableShippingMethods;
      if (applicableShippingMethods && applicableShippingMethods.length) {
        setShippingMethods(applicableShippingMethods);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectShipmentMethodId(value);
    setLoader(true);
    try {
      const response = await updateShipmentDetails(shippmentId, value);
      const { basketId } = response?.data;
      if (basketId) {
        fetchItems();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="bag-shippment__interface">
      {shipmentPromotionName && shipmentPromotionPrice ? (
        <div className="bag-shippment__promotion">
          <p className="bag-shippment__promotion-name">{shipmentPromotionName}</p>
          <p className="bag-shippment__promotion-price">-${shipmentPromotionPrice}</p>
        </div>
      ) : null}
      <p className="bag-shippment__label">Shipping</p>
      {shippingMethods && shippingMethods.length ? (
        <select
          className={`bag-shippment__select ${
            bagValidationError && !shippmentMethodId ? "bag-shippment__select-error" : ""
          }`}
          onChange={handleSelectChange}
          value={selectShipmentMethodId}
        >
          {bagValidationError && !shippmentMethodId ? (
            <option className="bag-shippment__option-error">Please select Shipment Method</option>
          ) : (
            <option value="" key={0}>
              Please select Shipment Method
            </option>
          )}
          {shippingMethods.map((shipment: any, index: number) => (
            <option value={shipment.id} key={index + 1}>
              {" "}
              {shipment.name} ( {shipment.description} ){" "}
            </option>
          ))}
        </select>
      ) : null}
    </div>
  );
};

export default BagShippment;
