import { EventType, pushEvent } from "./index";

/**
 * Store Locator
 */
export const scCartView = (productItems: any) => {
  const eventLabel = productItems ? productItems.reduce((acc, { productId }) => acc + productId + "|", "") : "";
  if (!eventLabel.length) {
    return "";
  }
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.cartview,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.lead
  });
};
