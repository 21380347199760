import React, { FunctionComponent, useState, useEffect, ChangeEvent, KeyboardEvent, useContext } from "react";
import { addPromoCode, deletePromoCode } from "../../services/salesforce/Bag";
import ModalBlock from "../Modal";
import EcommerceContext from "../../services/EcommerceContextService";
import Button from "../Button";

import "./styles.scss";

interface BagPromoCodeInterface {
  couponId: string;
  couponCode: string;
  setLoader: (loader: boolean) => void;
}

const BagPromoCode: FunctionComponent<BagPromoCodeInterface> = props => {
  const { couponId, couponCode, setLoader } = props;
  const [promoCode, setPromoCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [couponItemId, setCouponItemId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { setBagItems, fetchItems } = useContext(EcommerceContext);
  const modalBlockData = {
    productName: couponCode
  };

  const handlePromoCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setPromoCode(value);
  };

  const handlePromoKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    setErrorMessage("");
    try {
      const response = await addPromoCode(promoCode);
      const { detail, title, couponItems, basketId } = response?.data;
      if (title && detail) {
        setErrorMessage(detail);
      }
      if (basketId) {
        fetchItems();
        setPromoCode("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setCouponItemId(couponId);
  }, [couponId]);

  const handlePromoDelete = async () => {
    setShowModal(false);
    setLoader(true);
    setErrorMessage("");
    try {
      const response = await deletePromoCode(couponItemId);
      const { basketId } = response?.data;
      if (basketId) {
        setBagItems(response?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="bag-promo__wrapper">
      <p className="bag-promo__label">Enter Promo Code</p>
      <div className="bag-promo__submit-wrapper">
        <input
          type="text"
          className="bag-promo__input"
          placeholder="Promo Code"
          value={promoCode}
          onChange={handlePromoCodeChange}
          onKeyDown={handlePromoKeyPress}
        />
        <Button
          variant="nonary"
          type="submit"
          form="br-50"
          className="bag-promo__submit-button"
          onClick={handleSubmit}
          disabled={!promoCode}
        >
          Submit
        </Button>
      </div>
      {errorMessage && <p className="bag-promo__errorPromo">{errorMessage}</p>}
      <div className="bag-promo__submission">
        {couponItemId && (
          <div className="bag-promo__successfull-promo">
            <p className="bag-promo__successfull-label">
              <span className="bag-promo__successfull-promo-code">{couponCode} - </span> has been applied to your order
            </p>
            <button className="bag-promo__delete-promo" type="submit" onClick={() => setShowModal(true)}>
              x
            </button>
            <ModalBlock
              data={modalBlockData}
              showModal={showModal}
              handleSubmit={handlePromoDelete}
              handleClose={() => setShowModal(false)}
              modalConfirm="Yes"
              modalCancel="Cancel"
              ModalBody={
                <>
                  <span className="modal-block__message">
                    Are you sure you want to remove the following item from the bag?
                  </span>
                  <span className="modal-block__productName">{couponCode}</span>
                </>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BagPromoCode;
